@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;

}
* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.hide-scrollbar::-webkit-scrollbar{
  width: 0;
  background-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb{
  background-color: transparent;
}


/*Scroll*/
.scrollbar-main::-webkit-scrollbar {
  width: 4px;
}
.scrollbar-main::-webkit-scrollbar-thumb {
  border: 4px solid #0370F3;
  border-radius: 4px;
}

.scrollbar-grey::-webkit-scrollbar {
  width: 4px;
}
.scrollbar-grey::-webkit-scrollbar-track {
  background-color: #DFE3E8;
  border-radius: 4px;
}
.scrollbar-grey::-webkit-scrollbar-thumb {
  border: 2px solid #919EAB;
  border-radius: 4px;
}